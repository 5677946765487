'use client'

import {
  ComponentProps,
  ReactNode,
  ReactElement,
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react'
import classNames from 'classnames/bind'
import { X24 } from '@vinted/monochrome-icons'

import styles from './Notification.scss'

import Card from '../Card'
import Cell from '../Cell'
import Icon from '../Icon'
import type { Props as IconProps } from '../Icon/Icon'
import Text from '../Text'
import Button from '../Button'
import { getTestId } from '../../utils/testId'

enum CloseType {
  CloseButtonClick = 'close_button_click',
  BodyClick = 'body_click',
  Auto = 'auto',
}

type Props = {
  iconName?: ComponentProps<typeof Icon>['name']
  icon?: ReactElement<IconProps>
  body: ReactNode
  suffix?: JSX.Element | string | null | false
  onClick?: () => void
  displayDuration?: number
  forceVisibility?: boolean
  onClose?: (closeType: CloseType | `${CloseType}` | undefined) => void
  /**
   * Adds data-testid attribute to parent and children components.
   * When used, --suffix, --close-button, --icon and --body suffixes applied accordingly.
   */
  testId?: string
  /**
   * Provides accessible label for the suffix button.
   */
  actionButtonLabel?: string
}

const MS_PER_SECOND = 1000
const DELAY_DURATION = 500

const cssClasses = classNames.bind(styles)

const Notification = ({
  displayDuration = 5,
  forceVisibility,
  onClose,
  iconName,
  icon,
  onClick,
  body,
  suffix,
  actionButtonLabel,
  testId,
}: Props) => {
  const [visible, setVisible] = useState(true)
  const timeoutRef = useRef<number | undefined>()
  const onCloseRef = useRef(onClose)
  onCloseRef.current = onClose

  const close = useCallback((type: CloseType) => {
    clearTimeout(timeoutRef.current)
    setTimeout(() => onCloseRef.current?.(type), DELAY_DURATION)
    setVisible(false)
  }, [])

  useEffect(() => {
    if (forceVisibility || !displayDuration) return undefined

    setVisible(true)
    timeoutRef.current = window.setTimeout(
      () => close(CloseType.Auto),
      displayDuration * MS_PER_SECOND,
    )

    return () => clearTimeout(timeoutRef.current)
  }, [forceVisibility, displayDuration, close])

  const handleClick = () => {
    if (!onClick) return

    onClick()
    close(CloseType.BodyClick)
  }

  const renderIcon = () => {
    if (!icon && !iconName) return null

    return (
      <div className={styles.icon}>
        {icon || (iconName && <Icon name={iconName} testId={getTestId(testId, 'icon')} />)}
      </div>
    )
  }

  const renderBody = () => {
    if (!body) return null

    return <Text as="span" text={body} testId={getTestId(testId, 'body')} />
  }

  const renderSuffix = () => {
    if (typeof suffix === 'boolean') {
      return null
    }

    if (suffix) return <span data-testid={getTestId(testId, 'suffix')}>{suffix}</span>

    const handleDefaultSuffixClick = () => close(CloseType.CloseButtonClick)
    const defaultSuffix = (
      <Button
        iconName={X24}
        inline
        onClick={handleDefaultSuffixClick}
        styling={Button.Styling.Flat}
        testId={getTestId(testId, 'close-button')}
        aria-label={actionButtonLabel}
      />
    )

    return defaultSuffix
  }

  const notificationClass = cssClasses(styles.notification, {
    [styles['fade-in']]: visible,
    [styles['fade-out']]: !visible,
  })

  return (
    <div className={notificationClass} data-testid={testId}>
      <Card styling={Card.Styling.Elevated}>
        <div className={cssClasses(styles.content)}>
          <Cell
            prefix={renderIcon()}
            body={renderBody()}
            suffix={renderSuffix()}
            onClick={handleClick}
            type={onClick ? Cell.Type.Navigating : undefined}
          />
        </div>
      </Card>
    </div>
  )
}

Notification.CloseType = CloseType

export default Notification
