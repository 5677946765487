'use client'

import {
  ReactNode,
  Ref,
  FocusEvent,
  ChangeEvent,
  KeyboardEvent,
  MouseEvent,
  ForwardRefExoticComponent,
  RefAttributes,
  forwardRef,
  AriaAttributes,
} from 'react'
import classNames from 'classnames/bind'
import TextareaAutosize from 'react-textarea-autosize'

import { idFromName } from '../../../utils/html'
import { noop } from '../../../utils/noop'

import styles from '../Input.scss'
import { getTestId } from '../../../utils/testId'
import { deprecationWarning } from '../../../utils/warning'

const cssClasses = classNames.bind(styles)

enum Styling {
  Tight = 'tight',
  Narrow = 'narrow',
  Wide = 'wide',
}

enum Theme {
  Primary = 'primary',
}

export type Props = {
  name: string
  validation?: ReactNode
  title?: string | JSX.Element
  value?: string | number | null
  defaultValue?: string | number | null
  /** @deprecated Use minLength field instead */
  min?: string | number
  /** @deprecated Use maxLength field instead */
  max?: string | number
  minLength?: number
  maxLength?: number
  /** @deprecated Use suffix field instead */
  deprecatedIcon?: ReactNode
  suffix?: ReactNode
  placeholder?: string
  styling?: Styling
  theme?: Theme
  /**
   * Controls how many rows should be displayed.
   * If the content is longer, text component expands to accomodate it.
   */
  rows?: number
  /**
   * Controls the maximum number of rows that can be displayed.
   * If the text is longer, the content becomes scrollable.
   */
  maxRows?: number
  forwardedRef?: Ref<HTMLTextAreaElement>
  uncontrolled?: boolean
  required?: boolean
  disabled?: boolean
  spellCheck?: boolean
  aria?: AriaAttributes
  /**
   * Adds data-testid attribute to parent and children components.
   * When used, --title and --input suffixes applied accordingly.
   */
  testId?: string
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void
  onFocus?: (event: FocusEvent<HTMLTextAreaElement>) => void
  onBlur?: (event: FocusEvent<HTMLTextAreaElement>) => void
  onKeyPress?: (event: KeyboardEvent<HTMLTextAreaElement>) => void
  onKeyDown?: (event: KeyboardEvent<HTMLTextAreaElement>) => void
  onInputClick?: (event: MouseEvent<HTMLTextAreaElement>) => void
}

const InputTextarea = ({
  name,
  validation,
  title,
  required,
  disabled,
  minLength,
  maxLength,
  suffix,
  placeholder,
  spellCheck,
  styling,
  theme,
  maxRows,
  forwardedRef,
  uncontrolled,
  value,
  defaultValue,
  rows = 5,
  testId,
  aria,
  onChange = noop,
  onFocus = noop,
  onBlur = noop,
  onKeyPress = noop,
  onKeyDown = noop,
  onInputClick = noop,
}: Props) => {
  const inputClass = cssClasses(styles.input, styling, theme)

  const inputValue = (uncontrolled ? defaultValue : value) ?? undefined
  const AccessibleWrapper = title ? 'label' : 'div'

  return (
    <AccessibleWrapper htmlFor={idFromName(name)} className={inputClass} data-testid={testId}>
      {title ? (
        <div className={styles.title} data-testid={getTestId(testId, 'title')}>
          {title}
        </div>
      ) : null}
      <div className={styles.content}>
        <TextareaAutosize
          className={styles.value}
          name={name}
          id={idFromName(name)}
          value={uncontrolled ? undefined : inputValue}
          defaultValue={uncontrolled ? inputValue : undefined}
          required={required}
          disabled={disabled}
          minLength={minLength}
          maxLength={maxLength}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyPress={onKeyPress}
          onKeyDown={onKeyDown}
          onClick={onInputClick}
          ref={forwardedRef}
          minRows={rows}
          maxRows={maxRows}
          spellCheck={spellCheck}
          data-testid={getTestId(testId, 'input')}
          {...aria}
        />
        {suffix ? <div className={styles.suffix}>{suffix}</div> : null}
      </div>

      {validation ? <div className={styles.note}>{validation}</div> : null}
    </AccessibleWrapper>
  )
}

interface RefComponent
  extends ForwardRefExoticComponent<Props & RefAttributes<HTMLTextAreaElement>> {
  Styling: typeof Styling
  Theme: typeof Theme
}

export const InputTextareaWithForwardedRef = forwardRef<HTMLTextAreaElement, Props>(
  (props, ref) => {
    const forwardedProps = { ...props }

    if (forwardedProps.min) {
      forwardedProps.minLength = forwardedProps.minLength || Number(forwardedProps.min)

      deprecationWarning(
        'Property min on InputTextarea component has been deprecated. Use property minLength instead',
      )
    }

    if (forwardedProps.max) {
      forwardedProps.maxLength = forwardedProps.maxLength || Number(forwardedProps.max)

      deprecationWarning(
        'Property max on InputTextarea component has been deprecated. Use property maxLength instead',
      )
    }

    if (forwardedProps.deprecatedIcon) {
      forwardedProps.suffix = forwardedProps.suffix || forwardedProps.deprecatedIcon

      deprecationWarning(
        'Property icon on InputTextarea component has been deprecated. Use property suffix instead',
      )
    }

    return <InputTextarea forwardedRef={ref} {...forwardedProps} />
  },
) as RefComponent

InputTextareaWithForwardedRef.displayName = InputTextarea.name
InputTextareaWithForwardedRef.Styling = Styling
InputTextareaWithForwardedRef.Theme = Theme

export default InputTextareaWithForwardedRef
